<template>
    <div class="portal__wrapper">
        <div class="portal__header">
            <div class="portal__header-menu">
                    <a @click.prevent="toggleMenu" href="#"><img v-svg-inline src="@/assets/menu-portal.svg" /></a>
            </div>
            <router-link :to="{ name: 'home' }"><img class="portal__header-logo" v-svg-inline src="@/assets/app-images/svgs/dbyloi-logo.svg" /></router-link>
        </div>
        <div class="portal__main">
            <PortalMenu />
            <PortalBody />
       </div>
       <footer class="portal__footer">
            <p>Copyright 2021 | <router-link target="_blank" :to="{ name: 'privacy' }">PGSolutions Group Privacy and Cookie Policy</router-link></p>
       </footer>
      <div class="menu" :class="{ 'menu--move':!menuShow, 'menu--hidden': menuHidden }">
          <div class="menu__close">
              <a href="#" @click.prevent="closeMenu" class="menu__close-link">
                  <img  v-svg-inline src="@/assets/app-images/svgs/x.svg" />
              </a>
          </div>

          <img class="portal__header-logo" v-svg-inline src="@/assets/app-images/svgs/dbyloi-logo.svg" />

          <ul class="menu__items">
              <li class="menu__item"><span class="item"><a @click.prevent="handleNav('portal-home')">Home</a></span></li>
              <li class="menu__item"><span class="item"><a @click.prevent="handleNav('portal-players')">Players</a></span></li>
              <li class="menu__item"><span class="item"><a @click.prevent="handleNav('portal-product')">Product</a></span></li>
              <li class="menu__item"><span class="item"><a @click.prevent="handleNav('portal-organisations')">Organisations</a></span></li>
              <li class="menu__item"><span class="item"><a @click.prevent="handleNav('portal-pgsolutions')">PGSolutions</a></span></li>
              <li class="menu__item"><span class="item"><a @click.prevent="handleNav('portal-thankyou')">Thank You</a></span></li>
          </ul>
      </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import PortalMenu from '@/components/Portal/PortalMenu.vue';
import PortalBody from '@/components/Portal/PortalBody.vue';

@Options({
  props: {
    msg: String
  },
  components: {
      PortalMenu, PortalBody
  }
})
export default class PortalTmplate extends Vue {

  private menuShow = false;
  private menuHidden = true;
  // private menuShow = true;
  // private menuHidden = false;


  mounted()
  {
      this.$watch('show', (show: boolean) => {
          if(show) this.menuHidden = false;
      });
  }

  private toggleMenu()
  {
    console.log('toggleMenu');

    this.menuShow = !this.menuShow;
    this.menuHidden = !this.menuHidden;
  }

    public closeMenu()
    {
        this.menuHidden = true;

        setTimeout(() => {
          this.menuShow = false;
            this.$emit('close');
        }, 500);
    }

    private handleNav(anchor: string)
    {
        this.closeMenu()

        setTimeout(() => {
            this.$router.push({ name: anchor });
        }, 300);
    }

}
</script>